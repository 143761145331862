<script>
import omit from 'lodash.omit'
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import { getAction } from '@/command/netTool'
import DetailFormGroup from '../../../components/DetailFormGroup'
import moment from 'moment'

const getChildren = function getChildren(children) {
  return children.map((e) => {
    return {
      ...e,
      label: e.name,
      value: e.code,
      children: e.children && e.children.length > 0 ? getChildren(e.children) : null,
    }
  })
}

export default {
  name: 'studyFurtherDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        regMethod: '0',
        participantsNumber: 0,
        sort: '0',
      },
      areaCode: [],
      jwdAyy: [],
    }
  },
  mounted() {
    const { id } = this.$route.query
    getAction('/config/point/queryPointList', {
      type: 2,
    }).then((res) => {
      if (res.code == 200) {
        this.jwdAyy = res.data.map((e) => {
          if (id == e.id) {
            let icon = new AMap.Icon({
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
              imageSize: new AMap.Size(20, 40),
            })
            return {
              lng: e.longitude,
              lat: e.latitude,
              icon: icon,
              id: e.id,
            }
          } else {
            return {
              lng: e.longitude,
              lat: e.latitude,
              id: e.id,
            }
          }
        })
      } else {
        this.$message.error(res.msg)
      }
    })

    // 获取级联数据
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'areaCode',
    })

    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/farmActivity/detail?id=${id}`,
        })
        .then((result) => {
          this.detail.areaCode = [result.provinceCode, result.cityCode, result.areaCode]
          this.detail.dw = `${this.detail.lon}，${this.detail.lat}`
          this.$forceUpdate()
        })
    }
  },
  methods: {
    onOpenMap() {
      this.jwdAyy = []
      apiTool.openMap({
        title: '打开地图',
        value: this.jwdAyy,
        id: this.$route.query.id,
        onSuccess: (data) => {
          this.detail.lat = data.lat
          this.detail.lon = data.lng
          this.detail.dw = `${this.detail.lon}，${this.detail.lat}`
          let index = this.jwdAyy.findIndex((e) => e.id == data.id)
          let icon = new AMap.Icon({
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            imageSize: new AMap.Size(20, 40),
          })
          let obj = {
            lng: data.lng,
            lat: data.lat,
            icon: icon,
            id: data.id,
          }
          if (index > -1) {
            this.jwdAyy.splice(index, 1, obj)
          } else {
            this.jwdAyy.push(obj)
          }
          getAction('/api/gao', {
            location: `${this.detail.lon},${this.detail.lat}`,
          }).then((res) => {
            let { address, codes } = res.data
            this.detail.areaCode = codes
            this.detail.address = address
            this.$forceUpdate()
          })
          this.$forceUpdate()
        },
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称(76字符内)',
            type: 'input',
            cols: 12,
            key: 'name',
            props: {
              maxLength: 76,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '排序序号（0-100）',
            type: 'inputNumber',
            key: 'sort',
            cols: 12,
            props: {
              min: 0,
              max: 100,
            },
          },
          {
            name: '活动开始时间',
            type: 'datePicker',
            key: 'beginTime',
            cols: 12,
            props: {
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm:ss',
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '活动结束时间',
            type: 'datePicker',
            key: 'endTime',
            cols: 12,
            props: {
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm:ss',
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '研学报名',
            type: 'select',
            key: 'regMethod',
            cols: 6,
            typeData: [
              {
                name: '免报名',
                value: '0',
              },
              {
                name: '免费报名',
                value: '1',
              },
              {
                name: '付费报名',
                value: '2',
              },
            ],
            onChange: (a) => {
              if (a != '2') {
                this.detail.price = null
              }
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: this.detail.regMethod == '2',
            name: '报名费',
            type: 'inputNumber',
            key: 'price',
            cols: 6,
            props: {
              min: 0,
            },
          },
          {
            display: this.detail.regMethod !== '0',
            name: '报名截止时间',
            type: 'datePicker',
            key: 'lastRegistTime',
            cols: 12,
            props: {
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm:ss',
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '活动名额(0不限制)',
            type: 'inputNumber',
            key: 'participantsNumber',
            cols: 6,
            props: {
              min: 0,
            },
          },
          {
            display: this.detail.regMethod == '2',
            name: '退款',
            type: 'select',
            key: 'refundExplain',
            cols: 6,
            typeData: [
              {
                name: '报名截止前可退',
                value: '0',
              },
              {
                name: '不可退',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ].filter((e) => (e.display == undefined ? true : e.display)),
      }
    },
    getForm2() {
      return {
        title: '位置',
        type: 'cardForm',
        data: [
          {
            name: '经纬度 （例：120.184349，30.254460）',
            type: 'row',
            cols: 12,
            rules: [
              {
                required: true,
              },
            ],
            gutter: 5,
            children: [
              {
                type: 'input',
                cols: 21,
                key: 'dw',
                label: '经纬度',
                disabled: true,
                rules: [
                  {
                    required: true,
                    type: 'string',
                    message: '请输入经纬度',
                  },
                ],
              },
              {
                type: 'button',
                label: '定位',
                cols: 3,
                key: 'a1',
                props: {
                  type: 'primary',
                  icon: 'environment',
                  on: {
                    click: this.onOpenMap,
                  },
                },
              },
            ],
          },
          {
            name: '地址',
            type: 'row',
            cols: 12,
            rules: [
              {
                required: true,
              },
            ],
            gutter: 5,
            children: [
              {
                type: 'cascader',
                cols: 10,
                key: 'areaCode',
                label: '地址',
                typeData: this.areaCode,
                placeholder: '请选择地址',
                rules: [
                  {
                    required: true,
                    type: 'array',
                    message: '请选择地址',
                  },
                ],
              },
              {
                type: 'input',
                cols: 14,
                key: 'address',
                label: '地址',
                rules: [
                  {
                    required: true,
                    message: '请选择地址',
                  },
                ],
              },
            ],
          },
        ],
      }
    },
    getForm3() {
      return {
        type: 'cardForm',
        title: 'Banner(最多4张)',
        data: [
          {
            type: 'upload',
            maxLength: 4,
            cols: 24,
            key: 'imgUrl',
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string',
              },
            ],
          },
        ],
      }
    },
    getForm4() {
      return {
        type: 'cardForm',
        cols: 24,
        data: [
          {
            type: 'rich',
            key: 'content',
            cols: 24,
          },
        ],
      }
    },
    getSumbitData() {
      const codeText = ['province', 'city', 'area']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.detail.areaCode[i]
      })
      return obj
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (data) => {
            const codeData = this.getSumbitData()
            if (moment(data.beginTime) > moment(data.endTime)) {
              this.$message.warning('活动结束时间应在活动开始时间前！')
              return
            }
            if (moment(data.endTime) < moment(data.lastRegistTime)) {
              this.$message.warning('报名截止时间应在活动结束时间前！')
              return
            }

            if (!(data.lon && data.lat)) {
              this.$message.warning('请通过地位选择经纬度！')
              return false
            }
            let tenantArrList = JSON.parse(localStorage.getItem('tenantArr'))
            let tenantName = (
              tenantArrList.find((e) => e.tenantId == localStorage.getItem('appCode')) || { tenantName: '' }
            ).tenantName

            let displayList = [
              {
                publishingType: '0',
                appCode: localStorage.getItem('appCode'),
                appName: tenantName,
              },
            ]
            let arr = data.dw.split('，')
            data.lon = arr[0]
            data.lat = arr[1]

            let obj = {
              ...data,
              publishingManType: '0',
              farmSectionType: '0',
              publishingType: '0',
              visibleType: '0',
              ...codeData,
              displayList,
              upDown: this.detail.id ? this.detail.upDown : '0',
              groupType: '1',
            }

            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: '/farmActivity',
                params: {
                  ...obj,
                },
                isPost: false,
              })
              .then(() => {
                this.$router.back()
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.back(),
        },
      ]
      return {
        left,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.getForm2(), this.getForm3(), this.getForm4()]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
